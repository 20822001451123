import {
  memo, useMemo, useState, useCallback, useEffect,
  forwardRef, useRef, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
import head from 'lodash/head';
import size from 'lodash/size';
// MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
// Local
import { PATH_SHARED_CHAT, injectParams } from '../config/paths';
import { SHARED_LINK_URL, SHARED_LINK_PLACEHOLDER, MEDIUM_SIZE } from '../config/params';
import { useApi } from '../context/ApiProvider';
import { SharedLinkResponse } from '../graphql/types';
import FetchFailedAlert from '../components/FetchFailedAlert';
import ShareLinkBtn, { ShareLinkBtnAction } from './ShareLinkBtn';
import style from './ShareDlg.module.scss';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type ShareDlgState = 'create' | 'creating' | 'created' | 'loading' | 'updated';

type ShareDlgProps = {
  open: boolean;
  onClose?: () => void;
}

const ShareDlgPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

const ShareDlg: FunctionComponent<ShareDlgProps> = ({
  open = false,
  onClose
}) => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const [state, setState] = useState<ShareDlgState>('create');
  const [copied, setCopied] = useState(false);
  const [link, setLink] = useState(SHARED_LINK_PLACEHOLDER);
  const title = useMemo(() => {
    return {
      'create': 'share.create.title',
      'creating': 'share.create.title',
      'created': 'share.created.title',
      'loading': 'share.create.title',
      'updated': 'share.updated.title'
    }[state];
  }, [state]);
  const help = useMemo(() => {
    return {
      'create': { text: 'share.create.help', link: 'share.create.help.link' },
      'creating': { text: 'share.create.help', link: 'share.create.help.link' },
      'created': { text: 'share.created.help', link: 'share.created.help.link' },
      'loading': { text: 'share.create.help', link: 'share.create.help.link' },
      'updated': { text: 'share.created.help', link: 'share.created.help.link' },
    }[state];
  }, [state]);
  const action = useMemo(() => {
    const mapping: Record<ShareDlgState, ShareLinkBtnAction> = {
      'create': 'create',
      'creating': 'create',
      'created': 'copy',
      'loading': 'create',
      'updated': 'copy'
    };
    return mapping[state];
  }, [state]);

  const {
    getSharedLinks: { sharedLinks, getSharedLinks, pending, failed },
    createSharedLink: { createSharedLink, pending: createPending, failed: createFailed }
  } = useApi();

  useEffect(() => {
    if (chatId) {
      setState('loading');
      getSharedLinks?.(chatId);
    }
  }, [chatId, getSharedLinks]);

  useEffect(() => {
    if (size(sharedLinks) && !pending && !failed) {
      const sharedLink = head(sharedLinks)?.id;
      if (sharedLink) {
        setLink(`${SHARED_LINK_URL}${injectParams(PATH_SHARED_CHAT, {chatId: chatId, shareId: sharedLink})}`);
        if (state !== 'updated') {
          if (state === 'loading') setState('updated');
          else setState('created');
        }
      }
    } else {
      if ((state !== 'created') && (state !== 'updated')) {
        setLink(SHARED_LINK_PLACEHOLDER);
        setState('create');
      }
    }
  }, [chatId, sharedLinks, state, pending, failed]);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    if(copied) {
      interval.current = setInterval(() => {setCopied(false)}, 1000);
      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    }
    return undefined;
  }, [copied]);

  const handleShareLink = useCallback(() => {
    if (state === 'create') {
      setState('creating');
      createSharedLink?.({
        chat: toString(chatId),
        onCompleted: (data?: SharedLinkResponse | null) => {
          if (!isNil(data)) {
            const sharedLink = data.data?.id;
            setLink(`${SHARED_LINK_URL}${injectParams(PATH_SHARED_CHAT, {chatId: chatId, shareId: sharedLink})}`);
            setState('created');
            getSharedLinks?.(toString(chatId));
          }
        }
      });
    } else {
      if ((state === 'created') || (state === 'updated')) {
        navigator.clipboard.writeText(link).then(() => {
          setCopied(true);
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      }
    }
  }, [state, chatId, createSharedLink, getSharedLinks, link]);

  const handleClose = useCallback(() => onClose?.(), [onClose]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(MEDIUM_SIZE));

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box
          display='flex'
          alignItems='center'
        >
          {t(title)}
          <Box flexGrow='1'/>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <DialogContentText>
          {t(help['text'])}
          <Link color="inherit">{t(help['link'])}</Link>
        </DialogContentText>
        <Box
          display='flex'
          alignItems='center'
          sx={{
            border: '1px solid rgba(0 0 0 / 30%)',
            borderRadius: '2rem',
            p: '0.5rem',
            my: '1rem'
          }}
        >
          <Box className={style['shared-link']}>
            <Box className={style['fading']}>
              {link}
            </Box>
          </Box>
          <Box flexGrow='1'/>
          {isDesktop && <ShareLinkBtn
            action={copied ? 'copied' : action}
            onClick={handleShareLink}
            disabled={pending || createPending || copied}
          />}
        </Box>
        {!isDesktop &&
          <Box sx={{width: '100%', display: 'flex', justifyContent: 'end'}}>
            <ShareLinkBtn
              action={copied ? 'copied' : action}
              onClick={handleShareLink}
              disabled={pending || createPending || copied}
            />
          </Box>
        }
        {(failed || createFailed) && (<FetchFailedAlert/>)}
      </DialogContent>
    </Dialog>
  );
};

ShareDlg.propTypes = ShareDlgPropTypes;

export default memo(ShareDlg);
