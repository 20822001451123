import { memo, useState, useCallback, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// Local
import Markdown from './Markdown';
import { VerseCommentary } from '../graphql/types';
import style from './TabCommentaries.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '1rem' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type TabCommentariesProps = {
  commentaries?: VerseCommentary[] | null;
};

const TabCommentariesPropTypes = {
  commentaries: PropTypes.array as Validator<VerseCommentary[] | null>,
};

const TabCommentaries: FunctionComponent<TabCommentariesProps> = ({
  commentaries,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  return (
    <Card className={style['tab-commentaries-card']}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="basic tabs example"
        sx={{ pl: '1rem' }}
      >
        {map(commentaries, (commentary, index) => (
          <Tab
            label={
              <Typography
                sx={{
                  width: '9rem',
                  textTransform: 'none',
                  fontWeight: '500',
                }}
              >
                {commentary?.source}
              </Typography>
            }
            sx={{
              color: 'rgba(0 0 0 / 70%)',
              '& .Mui-selected': {
                color: 'blue',
              },
            }}
            key={index}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {map(commentaries, (commentary, index) => (
        <TabPanel value={value} index={index} key={index}>
          <Markdown text={commentary?.text} />
        </TabPanel>
      ))}
    </Card>
  );
};

TabCommentaries.propTypes = TabCommentariesPropTypes;

export default memo(TabCommentaries);
