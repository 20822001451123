import {
  memo, useMemo, useState, useCallback,
  useEffect, useRef, forwardRef, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
// Local
import { PATH_SHARED_VERSE, injectParams } from '../config/paths';
import { MEDIUM_SIZE, SHARED_VERSE_LINK_URL } from '../config/params';
import { useApi } from '../context/ApiProvider';
import ShareLinkBtn from './ShareLinkBtn';
import style from './ShareVerseDlg.module.scss';


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type ShareVerseDlgProps = {
  open: boolean;
  onClose?: () => void;
}

const ShareVerseDlgPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

const ShareVerseDlg: FunctionComponent<ShareVerseDlgProps> = ({
  open = false,
  onClose
}) => {
  const { t } = useTranslation();
  const { verseId } = useParams();
  const [copied, setCopied] = useState(false);
  const {
    getVerses: { getVerses, data },
  } = useApi();

  const link= useMemo(() =>
    SHARED_VERSE_LINK_URL + injectParams(PATH_SHARED_VERSE, { verseId }), [verseId]);

  const handleShareLink = useCallback(() => {
    if (link) {
      navigator.clipboard.writeText(link).then(() => {
        setCopied(true);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  }, [link]);

  const handleClose = useCallback(() => onClose?.(), [onClose]);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (verseId) getVerses?.(verseId);
  }, [verseId, getVerses]);

  const verseText = useMemo(() => {
    const {verse} = data || {};
    if (verse) return t('share.verse.text', { verse: `${verse.book.title} ${verse.chapter.number}:${verse.number}`});
    else return '';
  }, [t, data]);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    if(copied) {
      interval.current = setInterval(() => {setCopied(false)}, 1000);
      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    }
    return undefined;
  }, [copied]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(MEDIUM_SIZE));

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box
          display='flex'
          alignItems='center'
        >
          {t('share.verse.title')}
          <Box flexGrow='1'/>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <DialogContentText>
          {verseText}
        </DialogContentText>
        <Box
          display='flex'
          alignItems='center'
          sx={{
            border: '1px solid rgba(0 0 0 / 30%)',
            borderRadius: '2rem',
            p: '0.5rem',
            my: '1rem'
          }}
        >
          <Box className={style['shared-link']}>
            <Box className={style['fading']}>
              {link}
            </Box>
          </Box>
          <Box flexGrow='1'/>
          {isDesktop && <ShareLinkBtn
            action={copied ? 'copied' : 'copy'}
            onClick={handleShareLink}
          />}
        </Box>
        {!isDesktop &&
          <Box sx={{width: '100%', display: 'flex', justifyContent: 'end'}}>
            <ShareLinkBtn
              action={copied ? 'copied' : 'copy'}
              onClick={handleShareLink}
            />
          </Box>
        }
      </DialogContent>
    </Dialog>
  );
};

ShareVerseDlg.propTypes = ShareVerseDlgPropTypes;

export default memo(ShareVerseDlg);
