import { memo, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';
import size from 'lodash/size';
import toString from 'lodash/toString';
// MUI
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
// Local
import { PATH_CHAT } from '../config/paths';
import { useApi } from '../context/ApiProvider';
import ChatButton from './ChatButton';

type ListChatsProps = {
  chatId?: string;
  disabled?: boolean;
}

const ListChatsPropTypes = {
  chahId: PropTypes.string,
  disabled: PropTypes.bool
};

const ListChats: FunctionComponent<ListChatsProps> = ({
  chatId,
  disabled
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    getChats: { chats, refetch: refetchChats },
    chatDelete: { chatDelete },
    chatUpdate: { chatUpdate },
  } = useApi();

  const onDeleteChat = useCallback((chat: string) => {
    if(chat) {
      chatDelete?.({
        chat,
        onCompleted: () => {
          if (toString(chat) === toString(chatId)) navigate(PATH_CHAT);
          refetchChats?.();
        }
      });
    }
  }, [navigate, chatDelete, chatId, refetchChats]);

  const onRenameChat = useCallback((chat: string, name: string) => {
    if(chat && name) {
      chatUpdate?.({chat, name});
    }
  }, [chatUpdate]);

  return (
    <>
      {(size(chats) > 0) && (
        <>
          <ListItem key={'header'} sx={{ pt: '0.5rem', pb: '0.2rem' }}>
            <Box
              fontSize={'0.9rem'}
              fontWeight={'500'}
              color={'#616161'}
            >
              {t('drawer.chats.label')}
            </Box>
          </ListItem>
          {map(chats, (item, index) => (
            <ListItem key={index} sx={{ py: '1px' }}>
              <ChatButton
                chat={item.id}
                page={chatId}
                onDeleteChat={onDeleteChat}
                onRenameChat={onRenameChat}
                disabled={disabled}
                name={item.title}
              />
            </ListItem>
          ))}
        </>
      )}
    </>
  );
};

ListChats.propTypes = ListChatsPropTypes;

export default memo(ListChats);
