import { gql } from '@apollo/client';

export const BOOKS_QUERY = gql`
  query Books(
    $religion: String!
  ) {
    books(
      religion: $religion
    )
    @rest(
      type: "BooksPayload",
      path: "religions/{args.religion}/books"
    ) {
      data {
        id
        title
      }
    }
  }
`;
