import { memo, type FunctionComponent } from 'react';
// Local imports
import Login from '../components/home/Login';
import { Container } from '@mui/material';
import { EXTRA_SMALL_SIZE } from '../config/params';

const LoginPage: FunctionComponent = () => {
  return (
      <Container maxWidth={EXTRA_SMALL_SIZE}>
        <Login/>
      </Container>
  );
}

export default memo(LoginPage);
