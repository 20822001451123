import { memo, useMemo, useEffect, type FunctionComponent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import isNil from 'lodash/isNil';
// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Local
import { PATH_BOOK_READER, injectParams } from '../config/paths';
import { useApi } from '../context/ApiProvider';

const ShowBookHeader: FunctionComponent = () => {
  const { religion, bookId } = useParams();
  const navigate = useNavigate();
  const {
    getSources: { getSources, sources },
    getBooks: { getBooks, books }
  } = useApi();

  useEffect(() => {
    if(isNil(sources)) getSources?.();
  }, [sources, getSources]);

  useEffect(() => {
    if(isNil(books) && !isNil(bookId) && !isNil(religion)) getBooks?.(religion);
  }, [bookId, religion, books, getBooks]);

  const bible = useMemo(() => {
    return sources?.find(item => item.key === religion);
  }, [sources, religion]);

  const book = useMemo(() => {
    return books?.find(item => item.id === bookId);
  }, [books, bookId]);

  const handleBibleClick = () => {
    const path = injectParams(PATH_BOOK_READER, { religion });
    navigate(path);
  };

  return (
    <Box
      display='flex'
      alignItems='center'
    >
      {religion && (
        <Typography
          component='div'
          sx={{
            pl: '1rem',
            pr: '0.5rem',
            fontSize: '1.3rem',
            fontWeight: '500',
            color: 'rgba(0 0 0 / 70%)'
          }}
        >
          {bible?.religion} :{' '}
          <Typography
            component='span'
            onClick={handleBibleClick}
            sx={{
              fontSize: '1.3rem',
              fontWeight: '500',
              cursor: 'pointer',
              color: 'rgba(0 0 255 / 70%)',
              textDecoration: 'underline'
            }}
          >
            {bible?.bible}
          </Typography>
          {book?.title && ` / ${book?.title}`}
        </Typography>
      )}
    </Box>
  );
};

export default memo(ShowBookHeader);
