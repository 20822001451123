import PropTypes from 'prop-types';
import { CSSProperties, FunctionComponent, memo, MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//MUI
import { Box, IconButton, Tooltip } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
//LOCAL
import { useApi } from '../../context/ApiProvider';
import { PATH_CHAT } from '../../config/paths';

interface MenuSideBarHeaderProps {
  style?: CSSProperties;
}

const MenuSideBarHeader: FunctionComponent<MenuSideBarHeaderProps> = ({ style }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    getDrawer: { drawerOn, handleToggleDrawer },
  } = useApi();

  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const onNewChat = useCallback(() => {
    navigate(PATH_CHAT);
  }, [navigate]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={style}
      sx={{ width: '100%', height: 'fit-content' }}
    >
      <Tooltip
        title={t(drawerOn ? 'tooltip.hide.drawer' : 'tooltip.show.drawer')}
        arrow
      >
        <IconButton onClick={handleToggleDrawer} onMouseDown={handleMouseDown}>
          {drawerOn ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltip.new.chat')} arrow>
        <IconButton onClick={onNewChat} onMouseDown={handleMouseDown}>
          <QuestionAnswerOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

MenuSideBarHeader.propTypes = {
  style: PropTypes.object,
};

export default memo(MenuSideBarHeader);
