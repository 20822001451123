import { memo, useEffect, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { styled } from '@mui/material/styles';
// Local
import { PATH_BOOK_READER, injectParams } from '../config/paths';
import { Scripture } from '../graphql/types';
import { useApi } from '../context/ApiProvider';
import FadingTextButton from './FadingTextButton';

const SourceButton = styled(FadingTextButton)({
  display: 'flex',
  justifyContent: 'flex-start',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  textTransform: 'none',
  alignItems: 'left',
  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: '400',
  padding: '6px 6px 6px 10px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#fafafa',
  borderColor: '#fafafa',
  color: 'rgba(0, 0, 0, 0.87)',
  minWidth: '16rem',
  '&:hover': {
    backgroundColor: '#bdbdbd',
    borderColor: '#bdbdbd',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#bdbdbd',
    borderColor: '#bdbdbd',
  },
  '&:focus': {
    boxShadow: 'none'
  }
});

type ListSourcesProps = {
  disabled?: boolean;
}

const ListSourcesPropTypes = {
  disabled: PropTypes.bool
};

const ListSources: FunctionComponent<ListSourcesProps> = ({
  disabled = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getSources: { getSources, sources, failed } } = useApi();

  const onClick = useCallback((item: Scripture) => {
    navigate(injectParams(PATH_BOOK_READER, {religion: item.key}));
  }, [navigate]);

  useEffect(() => {
    if(isUndefined(sources) && !failed) getSources?.();
  }, [sources, failed, getSources]);

  return (
    <>
      <ListItem key={'header'} sx={{ pb: 0, pt: 0 }}>
        <Box
          fontSize={'0.9rem'}
          fontWeight={'500'}
          color={'#616161'}
          pb={1}
        >
          {t('drawer.sources.label')}
        </Box>
      </ListItem>
      {map(sources, (item, index) => (
        <ListItem key={index} sx={{ pb: 0, pt: 0 }}>
          <SourceButton onClick={() => onClick(item)} startIcon={<LocalLibraryIcon/>} disabled={disabled}>
            {item.bible}
          </SourceButton>
        </ListItem>
      ))}
    </>
  );
};

ListSources.propTypes = ListSourcesPropTypes;

export default memo(ListSources);
