import { memo, useEffect, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// local
import { ARTICLES_BACKGROUND } from '../../config/about';
import { useApi } from '../../context/ApiProvider';
import style from './Medium.module.scss';

const Medium: FunctionComponent = () => {
  const { t } = useTranslation();
  const { getMedium: { getMedium, failed, articles } } = useApi();

  useEffect(() => {
    if(isNil(articles)) getMedium?.();
  }, [articles, getMedium]);

  const wrapper = (element: React.ReactNode, index: number) => {
    const background = ARTICLES_BACKGROUND[index % ARTICLES_BACKGROUND.length];

    return (
      <Box key={index} sx={{ m: '1rem' }}>
        <Box
          sx={{
            width: '20rem',
            height: '30rem',
            backgroundColor: '#f5f5f5',
            borderRadius: '0.5rem',
            backgroundImage: background ? `url(${background})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: '0.95'
          }}
          className={style['wrapper']}
        >
          {element}
        </Box>
      </Box>
    );
  };

  if (failed) return <div/>;

  return (
    <Box>
      <Box pl={'5.5rem'} pt={'1rem'}>
        <Typography variant='h5' component='div'>
          {t('home.articles.label')}
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-star'
        className={style['medium-slider']}
      >
        {map(articles,({title, preview_text, url}, index) => (
          wrapper(
            <Link
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              <div className={style['slide']}>
                <div className={style['title']}>{title}</div>
                <div className={style['text']}>{preview_text}</div>
              </div>
            </Link>,
            index
          )
        ))}
      </Box>
    </Box>
  );
};

export default memo(Medium);
