import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
// Local
import { PAGE_SIZE_LARGE } from '../../config/params';
import { PATH_SHARED_VERSE, injectParams } from '../../config/paths';
import { useApi } from '../../context/ApiProvider';
import FetchFailedAlert from '../FetchFailedAlert';

type BookPageProps = {
  bookId?: string;
  startPage?: number,
  currentVerse?: string,
}

const BookPage: React.FC<BookPageProps> = ({
  bookId,
  startPage,
  currentVerse,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(startPage || 1);
  const { getBookPage: { getBookPage, bookPage, pending, failed } } = useApi();

  useEffect(() => {
    if (!isNil(page) && !isNil(bookId)) getBookPage?.(bookId, toString(page), PAGE_SIZE_LARGE);
  }, [page, getBookPage, bookId]);

  const handleNext = () => {
    if (!isNil(bookPage?.next)) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (!isNil(bookPage?.previous)) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleClick = useCallback((verseId: string) => {
    navigate(injectParams(PATH_SHARED_VERSE, { verseId }));
  }, [navigate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        padding: '0',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <div style={{ flexGrow: 1, overflowY: 'auto', padding: '0' }}>
        {(failed && <FetchFailedAlert/>) ||
        (pending && (
          <Stack sx={{ width: '100%' }} spacing={1}>
            <LinearProgress/>
            <LinearProgress/>
            <LinearProgress/>
          </Stack>
        )) || (
          <div style={{ padding: '0 1rem' }}>
            {bookPage?.results.map((verse, index) => {
              const shouldAddNewLine = parseInt(verse.number, 10) === 1;

              return (
                <React.Fragment key={verse.id}>
                  {shouldAddNewLine && (
                    <h3
                      style={{
                        marginTop: '1rem',
                        marginBottom: '1rem',
                        fontWeight: 'bold',
                        color: 'rgba(0 0 0 / 70%)'
                      }}
                    >
                      {t('book.reader.chapter', {chapter: verse.chapter.number})}
                    </h3>
                  )}
                  <span>
                    <sup
                      onClick={() => handleClick(verse.id)}
                      style={{
                        fontSize: '0.8rem',
                        cursor: 'pointer',
                        color: 'rgba(0 0 0 / 60%)',
                        textTransform: 'lowercase',
                        fontVariant: 'small-caps',
                        paddingRight: '0.2rem',
                        paddingLeft: index === 0 || shouldAddNewLine ? '0' : '0.5rem',
                        verticalAlign: 'super'
                      }}
                    >
                      {t('book.reader.chapter.verse',
                        {chapter: verse.chapter.number, verse: verse.number})}
                    </sup>
                    <span style={{ fontSize: '1.1rem', paddingBottom: 0, color: 'rgba(0 0 0 / 70%)', backgroundColor: currentVerse === `${verse.chapter.number}:${verse.number}` ? 'yellow' : 'none'}}>
                      {verse.text}
                    </span>
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        style={{
          padding: '1rem',
          borderTop: '1px solid #e0e0e0',
          position: 'sticky',
          bottom: 0,
          zIndex: 1000
        }}
      >
        <Button variant="contained" onClick={handlePrevious} disabled={!bookPage?.previous}>
          {t('book.reader.previous')}
        </Button>
        <Typography variant="body1">
          {(!pending && !failed) && (
            t('book.reader.page', { page, pageCount: bookPage?.page_count})
          )}
        </Typography>
        <Button variant="contained" onClick={handleNext} disabled={!bookPage?.next}>
          {t('book.reader.next')}
        </Button>
      </Box>
    </div>
  );
};

BookPage.propTypes = {
  bookId: PropTypes.string
};

export default memo(BookPage);
