import { createContext, useContext, useCallback, useState, ReactNode } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
// Local
import useQueryCounted from '../hooks/useQueryCounted';
import useMutationMethod from '../hooks/useMutationMethod';
import useQueryObject from '../hooks/useQueryObject'
import { SCRIPTURES_QUERY } from '../graphql/Scriptures';
import { CHATS_QUERY } from '../graphql/Chats';
import { CHAT_HISTORY_QUERY } from '../graphql/ChatHistory';
import { DELETE_CHAT_MUTATION } from '../graphql/DeleteChat';
import { UPDATE_CHAT_MUTATION } from '../graphql/UpdateChat';
import { SEND_MESSAGE_MUTATION } from '../graphql/SendMessage';
import { CHAT_MESSAGE_QUERY } from '../graphql/ChatMessage';
import { RELATED_VERSES_QUERY } from '../graphql/RelatedVerses';
import { EXCERPT_VERSES_QUERY } from '../graphql/ExcerptVerses';
import { VERSE_COMMENTARIES_QUERY } from '../graphql/VerseCommentaries';
import { JESUS_SAID_QUERY } from '../graphql/JesusSaid';
import { HISTORICAL_BACKGROUND_QUERY } from '../graphql/HistoricalBackground';
import { GET_SHARED_CHAT_QUERY } from '../graphql/GetSharedChat';
import { LIST_SHARED_LINKS_QUERY } from '../graphql/ListSharedLinks';
import { CREATE_SHARED_LINK_MUTATION } from '../graphql/CreateSharedLink';
import { DELETE_SHARED_LINK_MUTATION } from '../graphql/DeleteSharedLink';
import { CREATE_FIRST_CHAT_MUTATION } from '../graphql/CreateFirstChat';
import { SETTINGS_QUERY } from '../graphql/Settings';
import { SET_SETTINGS_MUTATION } from '../graphql/SetSettings';
import { GET_MEDIUM_QUERY } from '../graphql/GetMedium';
import { BOOKS_QUERY } from '../graphql/Books';
import { BOOK_PAGE_QUERY } from '../graphql/BookPage';
import {
  Scripture, ScripturesDocument,
  Chat, ChatsDocument,
  ChatMessage, ChatHistoryDocument,
  SendMessageDocument, SendMessageMutation, SendMessageResponse,
  RelatedVersesDocument, RelatedVersesData,
  Verse, ExcerptVersesDocument,
  VerseCommentariesDocument, VerseCommentary,
  JesusSaidDocument, JesusSaid,
  HistoricalBackgroundDocument, HistoricalBackground,
  UpdateChatDocument, DeleteChatDocument,
  ChatMessagePayload, ChatMessageDocument, ChatMessageQuery,
  SharedChatPayload, GetSharedChatDocument,
  Share, ListSharedLinksDocument,
  CreateSharedLinkMutation, CreateSharedLinkDocument,
  SharedLinkResponse, DeleteSharedLinkDocument,
  CreateFirstChatResponse, CreateFirstChatDocument, CreateFirstChatMutation,
  Settings, SettingsDocument, SetSettingsDocument,
  MediumArticle, GetMediumDocument,
  Book, BooksDocument, BookPageDocument, BookPagePayload
} from '../graphql/types';
import { cache as appCache } from '../graphql/cache';

interface BaseApi {
  pending: boolean;
  failed?: boolean;
}

interface GetSourcesType extends BaseApi {
  getSources?: () => void;
  sources?: Scripture[];
}

interface GetChatsType extends BaseApi {
  getChats: () => void;
  chats?: Chat[];
  refetch?: () => void;
}

interface GetHistoryType extends BaseApi {
  history?: ChatMessage[];
  getHistory?: (chat: string) => void;
  refetch?: () => void;
  previousParams?: { chat: string };
}

interface ChatDeleteType extends BaseApi {
  chatDelete?: (options?: {
    chat: string,
    onCompleted: () => void
  }) => void;
}

interface ChatUpdateType extends BaseApi {
  chatUpdate?: (options?: {
    chat: string,
    name: string
  }) => void;
}

interface SendMessageType extends BaseApi {
  sendMessage?: (options?: {
    chat: string,
    message: string,
    source: string,
    onCompleted?: (data?: SendMessageResponse | null) => void
  }) => void;
}

interface GetMessageType extends BaseApi {
  message?: ChatMessagePayload;
  getMessage?: (options?: {
    id: string,
    chat: string,
    onCompleted?: (data?: ChatMessagePayload | null) => void
  }) => void;
  setPolling?: (messageId: string, polling: boolean) => void;
  polling?: boolean;
}

interface GetVersesType extends BaseApi {
  getVerses: (verse: string) => void;
  data?: RelatedVersesData;
}

interface GetExcerptType extends BaseApi {
  getExcerpt: (verse: string, pageSize: string) => void;
  excerptVerses?: Verse[];
}

interface GetCommentariesType extends BaseApi {
  getCommentaries: (verse: string) => void;
  commentaries?: VerseCommentary[];
}

interface GetJesusSaidType extends BaseApi {
  getSaying: (verse: string) => void;
  saying?: JesusSaid;
}

interface GetHistoricalBackgroundType extends BaseApi {
  getHBackground: (verse: string) => void;
  background?: HistoricalBackground;
}

interface DrawerType {
  handleToggleDrawer: () => void;
  handleSetDrawer: (state: boolean) => void;
  drawerOn: boolean;
}

interface PreviousChatHistoryType {
  setPHistory: (history: ChatMessage[] | undefined) => void;
  prevHistory: ChatMessage[] | undefined;
}

interface GetSharedChatType extends BaseApi {
  sharedChat?: SharedChatPayload;
  getSharedChat?: (chat: string, share: string) => void;
  refetch?: () => void;
}

interface ListSharedLinksType extends BaseApi {
  sharedLinks?: Share[];
  getSharedLinks?: (chat: string) => void;
}

interface CreateShareLinkType extends BaseApi {
  createSharedLink?: (options?: {
    chat: string,
    onCompleted: (data?: SharedLinkResponse | null) => void
  }) => void;
}

interface DeleteShareLinkType extends BaseApi {
  deleteSharedLink?: (options?: {
    chat: string,
    share: string,
    onCompleted: () => void
  }) => void;
}

interface CreateFirstChatType extends BaseApi {
  createFirstChat?: (options?: {
    source: string,
    onCompleted: (data?: CreateFirstChatResponse | null) => void
  }) => void;
}

interface GetSettingsType extends BaseApi {
  getSettings?: () => void;
  settings?: Settings;
}

interface SetSettingsType extends BaseApi {
  setSettings?: (options?: {
    scripture: string,
    onCompleted?: () => void
  }) => void;
}

interface GetMediumType extends BaseApi {
  getMedium: () => void;
  articles?: MediumArticle[];
}

interface GetBooksType extends BaseApi {
  getBooks?: (religion: string) => void;
  books?: Book[];
}

interface GetBookPageType extends BaseApi {
  getBookPage?: (bookId: string, page: string, pageSize: string) => void;
  bookPage?: BookPagePayload;
}

interface ApiContextType {
  getSources: GetSourcesType;
  getChats: GetChatsType;
  getHistory: GetHistoryType;
  chatDelete: ChatDeleteType;
  chatUpdate: ChatUpdateType;
  sendMessage: SendMessageType;
  getMessage: GetMessageType;
  getVerses: GetVersesType;
  getExcerpt: GetExcerptType;
  getCommentaries: GetCommentariesType;
  getSaying: GetJesusSaidType;
  getHBackground: GetHistoricalBackgroundType;
  getDrawer: DrawerType;
  getPCH: PreviousChatHistoryType;
  getSharedChat: GetSharedChatType;
  getSharedLinks: ListSharedLinksType;
  createSharedLink: CreateShareLinkType;
  deleteSharedLink: DeleteShareLinkType;
  createFirstChat: CreateFirstChatType;
  getSettings: GetSettingsType;
  setSettings: SetSettingsType;
  getMedium: GetMediumType;
  getBooks: GetBooksType;
  getBookPage: GetBookPageType;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

// Create a Provider Component
export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const [drawerOn, setDrawerOn] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[] | undefined>(undefined);
  const [polling, setPollingMsg] = useState(false);

  const { query: sourcesGet, pending: sourcesPending,
          failed: sourcesFailed, results: sources } = useQueryCounted({
    data: undefined as unknown as Scripture,
    key: 'scriptures',
    lazyQuery: useLazyQuery(SCRIPTURES_QUERY as typeof ScripturesDocument)
  });
  const { query: chatsGet, refetch: chatsRefetch, pending: chatsPending,
          failed: chatsFailed, results: chats } = useQueryCounted({
    data: undefined as unknown as Chat,
    key: 'chats',
    lazyQuery: useLazyQuery(CHATS_QUERY as typeof ChatsDocument)
  });
  const { query: historyGet, refetch: historyRefetch, pending: historyPending,
          failed: historyFailed, variables: historyPrevParams, results: history } = useQueryCounted({
    data: undefined as unknown as ChatMessage,
    key: 'chatHistory',
    lazyQuery: useLazyQuery(CHAT_HISTORY_QUERY as typeof ChatHistoryDocument)
  });
  const { query: messageGet, pending: messageGetPending,
          failed: messageGetFailed, results: message } = useQueryObject({
    data: undefined as unknown as ChatMessagePayload,
    key: 'chatMessage',
    lazyQuery: useLazyQuery(CHAT_MESSAGE_QUERY as typeof ChatMessageDocument)
  });
  const { query: versesGet, pending: versesPending,
          failed: versesFailed, results: versesData } = useQueryObject({
    data: undefined as unknown as RelatedVersesData,
    key: 'relatedVerses',
    lazyQuery: useLazyQuery(RELATED_VERSES_QUERY as typeof RelatedVersesDocument)
  });
  const { query: excerptGet, pending: excerptPending,
          failed: excerptFailed, results: excerptVerses } = useQueryCounted({
    data: undefined as unknown as Verse,
    key: 'excerptVerses',
    lazyQuery: useLazyQuery(EXCERPT_VERSES_QUERY as typeof ExcerptVersesDocument)
  });
  const { query: commentariesGet, pending: commentariesPending,
          failed: commentariesFailed, results: commentariesData } = useQueryCounted({
    data: undefined as unknown as VerseCommentary,
    key: 'verseCommentaries',
    lazyQuery: useLazyQuery(VERSE_COMMENTARIES_QUERY as typeof VerseCommentariesDocument)
  });
  const { query: sayingGet, pending: sayingPending,
          failed: sayingFailed, results: sayingData } = useQueryObject({
    data: undefined as unknown as JesusSaid,
    key: 'jesusSaid',
    lazyQuery: useLazyQuery(JESUS_SAID_QUERY as typeof JesusSaidDocument)
  });
  const { query: backgroundGet, pending: backgroundPending,
          failed: backgroundFailed, results: backgroundData } = useQueryObject({
    data: undefined as unknown as HistoricalBackground,
    key: 'historicalBackground',
    lazyQuery: useLazyQuery(HISTORICAL_BACKGROUND_QUERY as typeof HistoricalBackgroundDocument)
  });
  const { mutate: chatDelete, loading: chatDeletePending, failed: chatDeleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_CHAT_MUTATION as typeof DeleteChatDocument)
  });
  const { mutate: chatUpdate, loading: chatUpdatePending, failed: chatUpdateFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_CHAT_MUTATION as typeof UpdateChatDocument, {
      update(cache, { data }) {
        if (!data?.updateChat?.data) return;
        const updateChat = data.updateChat.data;
        cache.modify({
          fields: {
            chats(existingChats = {}) {
              const results = existingChats.data.map((chatRef: Chat) => {
                return chatRef.id === updateChat.id
                  ? {...chatRef, title: updateChat.title}
                  : chatRef;
              });
              return {...existingChats, data: results};
            }
          }
        });
      }
    })
  });
  const { mutate: messageSend, loading: messageSendPending, failed: messageSendFailed } = useMutationMethod({
    mutation: useMutation(SEND_MESSAGE_MUTATION as typeof SendMessageDocument)
  });
  const { query: sharedChatGet, refetch: sharedChatRefetch, pending: sharedChatPending,
          failed: sharedChatFailed, results: sharedChat } = useQueryObject({
    flatResults: true,
    data: undefined as unknown as SharedChatPayload,
    key: 'getSharedChat',
    lazyQuery: useLazyQuery(GET_SHARED_CHAT_QUERY as typeof GetSharedChatDocument)
  });
  const { query: sharedLinksGet, pending: sharedLinksPending,
          failed: sharedLinksFailed, results: sharedLinks } = useQueryCounted({
    data: undefined as unknown as Share,
    key: 'listSharedLinks',
    lazyQuery: useLazyQuery(LIST_SHARED_LINKS_QUERY as typeof ListSharedLinksDocument)
  });
  const { mutate: sharedLinkCreate, loading: sharedLinkPending, failed: sharedLinkFailed } = useMutationMethod({
    mutation: useMutation(CREATE_SHARED_LINK_MUTATION as typeof CreateSharedLinkDocument)
  });
  const { mutate: sharedLinkDelete, loading: sharedLinkDeletePending,
          failed: sharedLinkDeleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_SHARED_LINK_MUTATION as typeof DeleteSharedLinkDocument)
  });
  const { mutate: firstChatCreate, loading: firstChatPending, failed: firstChatFailed } = useMutationMethod({
    mutation: useMutation(CREATE_FIRST_CHAT_MUTATION as typeof CreateFirstChatDocument)
  });
  const { query: settingsGet, pending: settingsPending,
          failed: settingsFailed, results: settings } = useQueryObject({
    data: undefined as unknown as Settings,
    key: 'settings',
    lazyQuery: useLazyQuery(SETTINGS_QUERY as typeof SettingsDocument)
  });
  const { mutate: settingsUpdate, loading: settingsUpdatePending, failed: settingsUpdateFailed } = useMutationMethod({
    mutation: useMutation(SET_SETTINGS_MUTATION as typeof SetSettingsDocument)
  });
  const { query: mediumGet, pending: mediumPending,
          failed: mediumFailed, results: articles } = useQueryCounted({
    data: undefined as unknown as MediumArticle,
    key: 'getMedium',
    lazyQuery: useLazyQuery(GET_MEDIUM_QUERY as typeof GetMediumDocument)
  });
  const { query: booksGet, pending: booksPending,
          failed: booksFailed, results: books } = useQueryCounted({
    data: undefined as unknown as Book,
    key: 'books',
    lazyQuery: useLazyQuery(BOOKS_QUERY as typeof BooksDocument)
  });
  const { query: bookPageGet, pending: bookPagePending,
          failed: bookPageFailed, results: bookPage } = useQueryObject({
    flatResults: true,
    data: undefined as unknown as BookPagePayload,
    key: 'bookPage',
    lazyQuery: useLazyQuery(BOOK_PAGE_QUERY as typeof BookPageDocument)
  });

  const handleToggleDrawer = useCallback(() => {
    setDrawerOn(!drawerOn);
  }, [drawerOn]);

  const handleSetDrawer = useCallback((state: boolean) => {
    setDrawerOn(state);
  }, []);

  const getSources = useCallback(() => {
    sourcesGet?.();
  }, [sourcesGet]);

  const getChats = useCallback(() => {
    chatsGet?.();
  }, [chatsGet]);

  const getHistory = useCallback((chat: string) => {
    historyGet?.({ variables: { chat } });
  }, [historyGet]);

  const getVerses = useCallback((verse: string) => {
    versesGet?.({ variables: { verse } });
  }, [versesGet]);

  const getExcerpt = useCallback((verse: string, pageSize: string) => {
    excerptGet?.({ variables: { verse, pageSize } });
  }, [excerptGet]);

  const getCommentaries = useCallback((verse: string) => {
    commentariesGet?.({ variables: { verse } });
  }, [commentariesGet]);

  const getSaying = useCallback((verse: string) => {
    sayingGet?.({ variables: { verse } });
  }, [sayingGet]);

  const getHBackground = useCallback((verse: string) => {
    backgroundGet?.({ variables: { verse } });
  }, [backgroundGet]);

  const deleteChat = useCallback((options?: {
    chat: string,
    onCompleted: () => void
  }) => {
    if (options) {
      const {chat, onCompleted} = options;
      chatDelete?.({
        variables: { chat },
        onCompleted: onCompleted
      });
    }
  }, [chatDelete]);

  const updateChat = useCallback((options?: {
    chat: string,
    name: string
  }) => {
    if (options) {
      const {chat, name: title} = options;
      chatUpdate?.({
        variables: { chat, input: { title } }
      });
    }
  }, [chatUpdate]);

  const sendMessage = useCallback((options?: {
    chat: string,
    message: string,
    source: string,
    onCompleted?: (data?: SendMessageResponse | null) => void
  }) => {
    if (options) {
      const {chat, message: text, source, onCompleted} = options;
      messageSend?.({
        variables: { chat, input: { text, source } },
        onCompleted: (data?: SendMessageMutation) => {
          onCompleted?.(data?.sendMessage);
        }
      });
    }
  }, [messageSend]);

  const getMessage = useCallback((options?: {
    id: string,
    chat: string,
    onCompleted?: (data?: ChatMessagePayload | null) => void
  }) => {
    if (options) {
      const {id, chat, onCompleted} = options;
      messageGet?.({
        variables: { id, chat },
        fetchPolicy: 'network-only',
        onCompleted: (data?: ChatMessageQuery) => {
          const message = data?.chatMessage?.data;
          if (message && message.status === 'ready') {
            appCache.modify({
              fields: {
                chatHistory(existingMessages = {}) {
                  const results = existingMessages.data.map((msgRef: ChatMessage) => {
                    return msgRef.id === message.id
                      ? {...msgRef, status: message.status, text: message.text, verses: message.verses }
                      : msgRef;
                  });
                  return {...existingMessages, data: results};
                }
              }
            });
          }
          onCompleted?.(data?.chatMessage);
        }
      });
    }
  }, [messageGet]);

  const setPolling = useCallback((messageId: string, msgPolling: boolean) => {
    const msg = last(history);
    if (!isUndefined(msg) && (msg.id === messageId)) {
      setPollingMsg(msgPolling);
    }
  }, [history]);

  const handleSetHistory = useCallback((history: ChatMessage[] | undefined) => {
    setChatHistory(history);
  }, []);

  const getSharedChat = useCallback((chat: string, share: string) => {
    sharedChatGet?.({ variables: { chat, share } });
  }, [sharedChatGet]);

  const getSharedLinks = useCallback((chat: string) => {
    sharedLinksGet?.({ variables: { chat }, fetchPolicy: 'network-only' });
  }, [sharedLinksGet]);

  const createSharedLink = useCallback((options?: {
    chat: string,
    onCompleted: (data?: SharedLinkResponse | null) => void
  }) => {
    if(options) {
      const {chat, onCompleted} = options;
      sharedLinkCreate?.({
        variables: { chat, input: { chat } },
        fetchPolicy: 'network-only',
        onCompleted: (data: CreateSharedLinkMutation) => onCompleted(data?.createSharedLink)
      });
    }
  }, [sharedLinkCreate]);

  const deleteSharedLink = useCallback((options?: {
    chat: string,
    share: string,
    onCompleted: () => void
  }) => {
    if (options) {
      const {chat, share, onCompleted} = options;
      sharedLinkDelete?.({
        variables: { chat, share },
        onCompleted: onCompleted
      });
    }
  }, [sharedLinkDelete]);

  const createFirstChat = useCallback((options?: {
    source: string,
    onCompleted: (data?: CreateFirstChatResponse | null) => void
  }) => {
    if(options) {
      const {source, onCompleted} = options;
      firstChatCreate?.({
        variables: { input: { source } },
        fetchPolicy: 'network-only',
        onCompleted: (data: CreateFirstChatMutation) => onCompleted(data?.createFirstChat)
      });
    }
  }, [firstChatCreate]);

  const getSettings = useCallback(() => {
    settingsGet?.();
  }, [settingsGet]);

  const setSettings = useCallback((options?: {
    scripture: string
    onCompleted?: () => void
  }) => {
    if (options) {
      const {scripture, onCompleted} = options;
      settingsUpdate?.({
        variables: { input: { scripture } },
        onCompleted: onCompleted
      });
    }
  }, [settingsUpdate]);

  const getMedium = useCallback(() => {
    mediumGet?.();
  }, [mediumGet]);

  const getBooks = useCallback((religion: string) => {
    booksGet?.({ variables: { religion } });
  }, [booksGet]);

  const getBookPage = useCallback((bookId: string, page: string, pageSize: string) => {
    bookPageGet?.({ variables: { bookId, page, pageSize } });
  }, [bookPageGet]);

  return (
    <ApiContext.Provider
      value={{
        getSources: {
          getSources: getSources,
          sources: sources,
          pending: sourcesPending,
          failed: sourcesFailed
        },
        getChats: {
          getChats: getChats,
          chats: chats,
          refetch: chatsRefetch,
          pending: chatsPending,
          failed: chatsFailed
        },
        getHistory: {
          history: history,
          getHistory: getHistory,
          refetch: historyRefetch,
          previousParams: historyPrevParams,
          pending: historyPending,
          failed: historyFailed
        },
        chatDelete: {
          chatDelete: deleteChat,
          pending: chatDeletePending,
          failed: chatDeleteFailed
        },
        chatUpdate: {
          chatUpdate: updateChat,
          pending: chatUpdatePending,
          failed: chatUpdateFailed
        },
        sendMessage: {
          sendMessage: sendMessage,
          pending: messageSendPending,
          failed: messageSendFailed
        },
        getMessage: {
          message: message,
          getMessage: getMessage,
          pending: messageGetPending,
          failed: messageGetFailed,
          setPolling: setPolling,
          polling: polling
        },
        getVerses: {
          getVerses: getVerses,
          data: versesData,
          pending: versesPending,
          failed: versesFailed
        },
        getExcerpt: {
          getExcerpt: getExcerpt,
          excerptVerses: excerptVerses,
          pending: excerptPending,
          failed: excerptFailed
        },
        getCommentaries: {
          getCommentaries: getCommentaries,
          commentaries: commentariesData,
          pending: commentariesPending,
          failed: commentariesFailed
        },
        getSaying: {
          getSaying: getSaying,
          saying: sayingData,
          pending: sayingPending,
          failed: sayingFailed
        },
        getHBackground: {
          getHBackground: getHBackground,
          background: backgroundData,
          pending: backgroundPending,
          failed: backgroundFailed
        },
        getDrawer: {
          handleToggleDrawer: handleToggleDrawer,
          handleSetDrawer: handleSetDrawer,
          drawerOn: drawerOn
        },
        getPCH: {
          setPHistory: handleSetHistory,
          prevHistory: chatHistory,
        },
        getSharedChat: {
          sharedChat: sharedChat,
          getSharedChat: getSharedChat,
          refetch: sharedChatRefetch,
          pending: sharedChatPending,
          failed: sharedChatFailed
        },
        getSharedLinks: {
          sharedLinks: sharedLinks,
          getSharedLinks: getSharedLinks,
          pending: sharedLinksPending,
          failed: sharedLinksFailed
        },
        createSharedLink: {
          createSharedLink: createSharedLink,
          pending: sharedLinkPending,
          failed: sharedLinkFailed
        },
        deleteSharedLink: {
          deleteSharedLink: deleteSharedLink,
          pending: sharedLinkDeletePending,
          failed: sharedLinkDeleteFailed
        },
        createFirstChat: {
          createFirstChat: createFirstChat,
          pending: firstChatPending,
          failed: firstChatFailed
        },
        getSettings: {
          getSettings: getSettings,
          settings: settings,
          pending: settingsPending,
          failed: settingsFailed
        },
        setSettings: {
          setSettings: setSettings,
          pending: settingsUpdatePending,
          failed: settingsUpdateFailed
        },
        getMedium: {
          getMedium: getMedium,
          articles: articles,
          pending: mediumPending,
          failed: mediumFailed
        },
        getBooks: {
          getBooks: getBooks,
          books: books,
          pending: booksPending,
          failed: booksFailed
        },
        getBookPage: {
          getBookPage: getBookPage,
          bookPage: bookPage,
          pending: bookPagePending,
          failed: bookPageFailed
        }
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

// Custom hook to use the ApolloContext
export const useApi = (): ApiContextType => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
