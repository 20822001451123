import { memo, type FunctionComponent } from 'react';
// Local
import Screen from '../components/Screen';
import ConfirmEmailPanel from '../Panels/ConfirmEmailPanel';

const ConfirmEmail: FunctionComponent = () => {
  return (
    <Screen>
      <ConfirmEmailPanel/>
    </Screen>
  );
};

export default memo(ConfirmEmail);
