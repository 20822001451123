import { gql } from '@apollo/client';

export const EXCERPT_VERSES_QUERY = gql`
  query ExcerptVerses(
    $verse: String!
    $pageSize: String!
  ) {
    excerptVerses(
      verse: $verse
      pageSize: $pageSize
    )
    @rest(
      type: "ExcerptVersesPayload",
      path: "verses/{args.verse}/excerpt?page_size={args.pageSize}"
    ) {
      data {
        id
        number
        text
        chapter {
          id
          number
        }
        book {
          id
          title
        }
        is_current
        translation
        reader_page_number  
      }
    }
  }
`;
