import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// MUI
import { Paper, Typography, Box } from '@mui/material';
// Local
import { PATH_BOOK_READER_BOOK, injectParams } from '../../config/paths';

interface BookPanelProps {
  id: string;
  title: string;
  religion: string;
}

const BookPanel: React.FC<BookPanelProps> = ({ id, title, religion }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(injectParams(PATH_BOOK_READER_BOOK, {religion, bookId: id, page: 1}));
  };

  return (
    <Box onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Paper elevation={1} style={{ padding: 16, textAlign: 'center' }}>
        <Typography variant="h6" color={'rgba(0 0 0 /70%)'}>{title}</Typography>
      </Paper>
    </Box>
  );
};

BookPanel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  religion: PropTypes.string.isRequired
};

export default memo(BookPanel);
