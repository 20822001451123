import { gql } from '@apollo/client';

export const BOOK_PAGE_QUERY = gql`
  query BookPage(
    $bookId: String!
    $page: String!
    $pageSize: String!
  ) {
    bookPage(
      bookId: $bookId
      page: $page
      pageSize: $pageSize
    )
    @rest(
      type: "BookPagePayload",
      path: "verses?book_id={args.bookId}&page={args.page}&page_size={args.pageSize}"
    ) {
      count
      next
      previous
      page_count
      results {
        id
        number
        text
        chapter {
          id
          number
        }
        book {
          id
          title
        }
      }
    }
  }
`;
