import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
// MUI
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
// Local
import { useApi } from '../../context/ApiProvider';
import FetchFailedAlert from '../FetchFailedAlert';
import BookPanel from './BookPanel';

interface BooksGridProps {
  religion?: string;
}

const BooksGrid: React.FC<BooksGridProps> = ({ religion }) => {
  const {
    getBooks: { getBooks, books, pending, failed }
  } = useApi();

  useEffect(() => {
    if (!isNil(religion)) getBooks?.(religion);
  }, [getBooks, religion]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', padding: '1rem' }}>
      {(failed && <FetchFailedAlert/>) ||
      (pending && (
        <Stack sx={{ width: '100%' }} spacing={1}>
          <LinearProgress/>
          <LinearProgress/>
          <LinearProgress/>
        </Stack>
      )) || (
        <Grid container spacing={2} style={{ padding: '1rem' }}>
          {map(books, (book) => (
            <Grid item xs={12} sm={6} md={3} key={book.id}>
              <BookPanel religion={toString(religion)} id={book.id} title={book.title} />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

BooksGrid.propTypes = {
  religion: PropTypes.string
};

export default memo(BooksGrid);
