// MUI
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
//local
import ShowBookHeader from '../components/ShowBookHeader';
import Reader from '../components/reader/Reader';
import DefaultHeader from '../components/common/DefaultHeader';
import { MEDIUM_SIZE } from '../config/params';

export default function BookReaderPanel() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(MEDIUM_SIZE));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100dvh' }}>
      {/* Header */}
      <DefaultHeader showBookHeader={isDesktop} />
      {/* Main Content Area */}
        <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          {!isDesktop && (
            <Box sx={{ py: '1rem' }}>
              <ShowBookHeader />
            </Box>
          )}
          <Reader />
      </Box>
    </Box>
  );
}
