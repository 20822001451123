import { type FunctionComponent, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// Local
import ProfileBtn from '../ProfileBtn';
import logoImage from '../../images/biblum-logo-transparent-1.png';

type HeaderProps = {
  hideAppName?: boolean;
};

const HeaderPropTypes = {
  hideAppName: PropTypes.bool,
};

const Header: FunctionComponent<HeaderProps> = ({ hideAppName }) => {
  const { t } = useTranslation();

  return (
    <AppBar position="sticky" elevation={0} sx={{ backgroundColor: '#f5f5f5' }}>
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '90%',
            mx: 'auto',
          }}
        >
          {!hideAppName && (
            <Box display="flex" alignItems="center">
              <img src={logoImage} width="40rem" />
              <Typography
                pl={'0.5rem'}
                variant="h6"
                component="div"
                color={'rgba(0 0 0 / 70%)'}
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                {t('home.title')}
              </Typography>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <ProfileBtn />
        </Box>
      </Toolbar>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '90%',
          mx: 'auto',
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <Typography
          pl={'0.5rem'}
          variant="h6"
          component="div"
          color={'rgba(0 0 0 / 70%)'}
          textAlign={'center'}
        >
          {t('home.title')}
        </Typography>
      </Box>
    </AppBar>
  );
};

Header.propTypes = HeaderPropTypes;

export default memo(Header);
