import { memo, useMemo, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery, useTheme } from '@mui/material';
// Local
import { PATH_CHATS, injectParams } from '../config/paths';
import VerseExplorerTitle from './VerseExplorerTitle';
import { SMALL_SIZE } from '../config/params';

type ShowVersesHeaderProps = {
  disabled?: boolean;
}

const ShowVersesHeaderPropTypes = {
  disabled: PropTypes.bool
};

const ShowVersesHeader: FunctionComponent<ShowVersesHeaderProps> = ({
  disabled
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chatId } = useParams();

  const path = useMemo(() => injectParams(PATH_CHATS, {chatId}), [chatId]);

  const handleClick = useCallback(() => navigate(path), [path, navigate]);

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down(SMALL_SIZE));

  return (
    <Box
      display='flex'
      alignItems='center'
    >
      {!isXSmallScreen && <VerseExplorerTitle useInline />}
      <Tooltip title={t('tooltip.go.back.to.chat')} arrow>
        <IconButton onClick={handleClick} disabled={disabled}>
          <SpeakerNotesOutlinedIcon/>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

ShowVersesHeader.propTypes = ShowVersesHeaderPropTypes;

export default memo(ShowVersesHeader);
