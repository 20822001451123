import { memo, useState, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// MUI
import IconButton from '@mui/material/IconButton';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Tooltip from '@mui/material/Tooltip';
// Local
import ShareDlg from './ShareDlg';
import ShareVerseDlg from './ShareVerseDlg';

type ShareProps = {
  verse?: boolean;
  disabled?: boolean;
}

const SharePropTypes = {
  verse: PropTypes.bool,
  disabled: PropTypes.bool
};

const Share: FunctionComponent<ShareProps> = ({
  verse,
  disabled
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
    setMounted(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setMounted(false);
  }, []);

  return (
    <>
      <Tooltip title={t('tooltip.share')} arrow>
        <IconButton
          size="large"
          color="primary"
          disabled={disabled}
          sx={{
            p: '0.844rem',
            color: 'rgba(0 0 0 / 60%)',
            '&:hover': {
              backgroundColor: 'rgba(0 0 0 / 5%)'
            },
            '&:active': {
              backgroundColor: 'rgba(0 0 0 / 5%)'
            }
          }}
          onClick={handleOpen}
        >
          <IosShareOutlinedIcon/>
        </IconButton>
      </Tooltip>
      {mounted && (
        (verse && (
          <ShareVerseDlg open={open} onClose={handleClose}/>
        )) || (
          <ShareDlg open={open} onClose={handleClose}/>
        )
      )}
    </>
  );
};

Share.propTypes = SharePropTypes;

export default memo(Share);
