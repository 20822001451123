import { memo, useMemo, useCallback, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import truncate from 'lodash/truncate';
// MUI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import IconButton from '@mui/material/IconButton';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Tooltip from '@mui/material/Tooltip';
// Local
import { PATH_RELATED_VERSES, PATH_SHARED_VERSE, injectParams } from '../config/paths';
import { Verse } from '../graphql/types';
import style from './VerseCard.module.scss';

type VerseCardProps = {
  chat?: string,
  verse?: Verse | null;
  unclickable?: boolean;
  truncated?: boolean;
}

const VerseCardPropTypes = {
  chat: PropTypes.string,
  verse: PropTypes.object as Validator<Verse | null>,
  unclickable: PropTypes.bool,
  truncated: PropTypes.bool
};

const VerseCard: FunctionComponent<VerseCardProps> = ({
  chat,
  verse,
  unclickable,
  truncated
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = useMemo(() => {
    if (verse) return `${verse.book.title} ${verse.chapter.number}:${verse.number}`;
    else return '';
  }, [verse]);

  const text = useMemo(() => truncated ? truncate(verse?.text, { length: 80 }) : verse?.text, [verse, truncated]);

  const path = useMemo(() => {
    if (chat) {
      return injectParams(PATH_RELATED_VERSES, {chatId: chat, verseId: verse?.id});
    } else {
      return injectParams(PATH_SHARED_VERSE, {verseId: verse?.id});
    }
  }, [chat, verse]);

  const handleClick = useCallback(() => {
    if (!unclickable) navigate(path);
  }, [path, navigate, unclickable]);

  return (
    <Box className={style['verse-card-body']}>
      <Card
        className={unclickable ? style['unclickable'] : style['clickable']}
        sx={{ p: truncated ? '0.5rem' : '1rem' }}
        onClick={handleClick}
      >
        <Box
          className={unclickable ? style['unclickable-title'] : style['title']}
          sx={{pb: truncated ? '0.2rem' : '0.7rem'}}
        >
          {(unclickable && (
            <Box
              display='flex'
              alignItems='center'
            >
              <Box mr={'0.5rem'}>
                {title}
              </Box>
              <Tooltip title={t('tooltip.read.source')} arrow>
                <IconButton>
                  <LocalLibraryIcon/>
                </IconButton>
              </Tooltip>
            </Box>
          )) || (
            <Box display='flex' alignItems='center'>
              <Box mr={1}>
                {title}
              </Box>
              <Box flexGrow={1}/>
              <AdsClickIcon fontSize='small' sx={{color: 'rgba(0 0 0 / 40%)'}}/>
            </Box>
          )}
        </Box>
        <Box>{text}</Box>
      </Card>
    </Box>
  );
};

VerseCard.propTypes = VerseCardPropTypes;

export default memo(VerseCard);
