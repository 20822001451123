import { memo, type FunctionComponent } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
// Local
import BooksGrid from './BooksGrid';
import BookPage from './BookPage';

const Reader: FunctionComponent = () => {
  const { religion, bookId, page } = useParams();
  const [searchParams] = useSearchParams();
  const _page = Number.parseInt(page || '1' )
  const currentVerse = searchParams.get('current') || undefined;
  return (
    <>
      {(bookId && (
        <BookPage bookId={bookId} startPage={_page} currentVerse={currentVerse}/>
      )) || (
        <BooksGrid religion={religion}/>
      )}
    </>
  );
};

export default memo(Reader);
