import {
  createRef,
  type FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
// MUI
import Box from '@mui/material/Box';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { useMediaQuery, useTheme } from '@mui/material';
// Local imports
import { injectParams, PATH_CHATS } from '../config/paths';
import { useApi } from '../context/ApiProvider';
import {
  type MessageItemRefs,
  type MsgItemRefs,
  type MutableMessageRefs,
} from '../components/types';
import { CreateFirstChatResponse, SendMessageResponse } from '../graphql/types';
import MessageInput from '../components/MessageInput';
import ChatHistory from '../components/ChatHistory';
import DefaultHeader from '../components/common/DefaultHeader';
import ScripturesSelect from '../components/ScripturesSelect';
import { SMALL_SIZE } from '../config/params';

type ChatPanelProps = {
  testSendMessage?: boolean;
};

const ChatPanelPropTypes = {
  testSendMessage: PropTypes.bool,
};

const ChatPanel: FunctionComponent<ChatPanelProps> = ({ testSendMessage }) => {
  const { chatId: cId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [passedState, setPassedState] = useState(location.state);
  const itemRefs: MutableMessageRefs = useRef<MessageItemRefs>(null);
  const [source, setSource] = useState('');

  const chatId = useMemo(() => {
    if (cId !== 'signup-confirmed') {
      return cId;
    }
    return undefined;
  }, [cId]);

  const {
    getChats: { getChats, refetch: refetchChats, chats, failed: chatsFailed },
    getHistory: {
      getHistory,
      refetch,
      pending,
      failed,
      history,
      previousParams,
    },
    sendMessage: {
      sendMessage,
      pending: messageSendPending,
      failed: messageSendFailed,
    },
    getMessage: { polling },
    getDrawer: { handleSetDrawer },
    createFirstChat: { createFirstChat },
  } = useApi();

  const loading = Boolean(chatId) && (pending || messageSendPending || polling);
  const error = Boolean(chatId) && (failed || messageSendFailed);

  useEffect(() => {
    if (isNil(chats) && !chatsFailed) getChats?.();
  }, [chats, chatsFailed, getChats]);

  useEffect(() => {
    if (!isNil(chats) && !size(chats)) {
      handleSetDrawer(false);
    }
  }, [chats, handleSetDrawer]);

  useEffect(() => {
    if (!isNil(chatId)) getHistory?.(chatId);
  }, [chatId, getHistory]);

  const historyItems = useMemo(() => {
    const { chat: prevChat } = previousParams || {};
    if (isNil(chatId) || toString(chatId) !== toString(prevChat) || pending)
      return undefined;
    if (history) {
      itemRefs.current = history.reduce((acc, _, index) => {
        acc[index] = createRef<HTMLLIElement>();
        acc[`ripple${index}`] = createRef<TouchRippleActions>();
        return acc;
      }, {} as MsgItemRefs);
    }
    return history;
  }, [chatId, history, previousParams, pending]);

  const onSelectSource = useCallback((sourceId: string) => {
    setSource(sourceId);
  }, []);

  const onSendMessage = useCallback(
    (message: string) => {
      if (size(message) && sendMessage) {
        sendMessage({
          chat: isNil(chatId) ? 'new' : chatId,
          message,
          source,
          onCompleted: (data?: SendMessageResponse | null) => {
            const chat = data?.data.bot.chat_id;
            if (!isNil(chatId)) {
              refetchChats?.();
              refetch?.();
            } else if (chat) {
              refetchChats?.();
              navigate(injectParams(PATH_CHATS, { chatId: chat }), {
                replace: true,
              });
            }
          },
        });
      }
    },
    [chatId, sendMessage, source, refetch, refetchChats, navigate]
  );

  useEffect(() => {
    if (chats) {
      const message = passedState?.message;
      const signup = passedState?.signup;
      if (signup && size(chats) == 0) {
        createFirstChat?.({
          source,
          onCompleted: (data?: CreateFirstChatResponse | null) => {
            const chat = data?.data.id;
            if (chat) {
              navigate(injectParams(PATH_CHATS, { chatId: chat }), {
                replace: true,
              });
            }
            refetchChats?.();
            setPassedState({ message: message });
          },
        });
      } else {
        if (size(source) && size(message)) {
          setPassedState(null);
          onSendMessage(message);
        }
      }
    }
  }, [
    passedState,
    onSendMessage,
    source,
    createFirstChat,
    refetchChats,
    navigate,
    chats,
  ]);

  useEffect(() => {
    if (testSendMessage) sendMessage?.({ chat: '1', message: '', source: '' });
  }, [testSendMessage, sendMessage]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(SMALL_SIZE));

  return (
    <Box className="page-wrapper">
      {/* Header */}
      <DefaultHeader
        onSelectScripture={onSelectSource}
        pending={loading}
        showSources
        showShare={!isNil(chatId)}
      />

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
      >
        {!isDesktop && (
          <Box p={'1rem'}>
            <ScripturesSelect disabled={loading} onSelect={onSelectSource} />
          </Box>
        )}
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
          {/* Right Column */}
          <ChatHistory
            chat={chatId}
            history={historyItems}
            itemRefs={itemRefs}
            pending={loading}
            failed={error}
          >
            {/* Footer */}
            <MessageInput
              onSendMessage={onSendMessage}
              pending={loading}
              disabled={!size(source)}
            />
          </ChatHistory>
        </Box>
      </Box>
    </Box>
  );
};

ChatPanel.propTypes = ChatPanelPropTypes;

// Default export
export default memo(ChatPanel);
