import { memo, useMemo, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
// MUI
import Grid, { type GridDirection } from '@mui/material/Grid';
// Local
import { Verse } from '../graphql/types';
import VerseCard from './VerseCard';
import style from './ListVerses.module.scss';

type ListVersesProps = {
  chat?: string;
  verses?: Verse[] | null;
  direction?: string;
  truncated?: boolean;
};

const ListVersesPropTypes = {
  chat: PropTypes.string,
  verses: PropTypes.array as Validator<Verse[] | null>,
  direction: PropTypes.string,
  truncated: PropTypes.bool,
};

const ListVerses: FunctionComponent<ListVersesProps> = ({
  chat,
  verses,
  direction,
  truncated,
}) => {
  const xd = useMemo(() => {
    return direction === 'column' ? 12 : 6;
  }, [direction]);

  return (
    <Grid
      container
      spacing={{ xs: 1, md: 1 }}
      className={style['verses-body']}
      direction={direction as GridDirection}
    >
      {map(verses, (verse, index) => (
        <Grid item key={index} xs={xd} sm={xd} md={xd}>
          <VerseCard verse={verse} truncated={truncated} chat={chat} />
        </Grid>
      ))}
    </Grid>
  );
};

ListVerses.propTypes = ListVersesPropTypes;

export default memo(ListVerses);
