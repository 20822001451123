import { useTranslation } from 'react-i18next';
// MUI
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { FunctionComponent } from 'react';

type VerseExplorerTitleProps = {
  useInline?: boolean;
};

const VerseExplorerTitlePropTypes = {
  useInline: PropTypes.bool,
};

const VerseExplorerTitle: FunctionComponent<VerseExplorerTitleProps> = ({
  useInline,
}) => {
  const { t } = useTranslation();

  return (
    <Typography
      component="div"
      sx={{
        pl: useInline ? '1rem' : '0',
        pr: useInline ? '0.5rem' : '0',
        fontSize: '1.3rem',
        fontWeight: '500',
        color: 'rgba(0 0 0 / 70%)',
      }}
    >
      {t('header.verse.explorer')}
    </Typography>
  );
};

VerseExplorerTitle.propTypes = VerseExplorerTitlePropTypes;

export default VerseExplorerTitle;
