import { gql } from '@apollo/client';

export const GET_MEDIUM_QUERY = gql`
  query GetMedium {
    getMedium
    @rest(
      type: "MediumPayload",
      path: "social/medium/recent-posts"
    ) {
      data {
        url
        title
        preview_text
        preview_image_url
        published_at
      }
    }
  }
`;
