import { memo, type FunctionComponent } from 'react';
// Local imports
import SignUp from '../components/home/Signup';
import { Container } from '@mui/material';
import { EXTRA_SMALL_SIZE } from '../config/params';

const SignupPage: FunctionComponent = () => {
  return (
        <Container maxWidth={EXTRA_SMALL_SIZE}>
            <SignUp/>
        </Container>
  );
}

export default memo(SignupPage);
