import {memo, type FunctionComponent} from 'react';
// Local imports
import Screen from '../components/Screen';
import SharedChatPanel from '../Panels/SharedChatPanel';

const SharedChat: FunctionComponent = () => {
  return (
    <Screen>
      <SharedChatPanel/>
    </Screen>
  );
}

// Default export
export default memo(SharedChat);
