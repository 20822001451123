import homeImage1 from '../images/biblum-home1.png';
import homeImage2 from '../images/biblum-home2.jpg';
import homeImage3 from '../images/biblum-home3.png';
import homeImage4 from '../images/biblum-home4.png';
import homeImage5 from '../images/biblum-home5.jpeg';
import homeImage6 from '../images/biblum-home6.png';
import slide2Bkg from '../images/slide-2.png';
import slide3Bkg from '../images/slide-3.jpg';
import slide4Bkg from '../images/slide-4.jpg';
import slide5Bkg from '../images/slide-5.jpg';
import slide6Bkg from '../images/slide-6.jpg';
import slide7Bkg from '../images/slide-7.jpg';

import m1 from '../images/m1.jpg';
import m2 from '../images/m2.jpg';
import m3 from '../images/m3.jpg';

export const ABOUT = [
  {img: homeImage1, title: 'app.about.title.1', text: 'app.about.text.1', background: slide2Bkg},
  {img: homeImage2, title: 'app.about.title.2', text: 'app.about.text.2', background: slide3Bkg, backgroundPosition: 'top left'},
  {img: homeImage3, title: 'app.about.title.3', text: 'app.about.text.3', background: slide4Bkg},
  {img: homeImage4, title: 'app.about.title.4', text: 'app.about.text.4', background: slide5Bkg, backgroundPosition: 'top left'},
  {img: homeImage5, title: 'app.about.title.5', text: 'app.about.text.5', background: slide6Bkg, backgroundPosition: 'top left',
    blist: ['app.about.text.5.1', 'app.about.text.5.2', 'app.about.text.5.3', 'app.about.text.5.4']},
  {img: homeImage6, title: 'app.about.title.6', text: 'app.about.text.6', background: slide7Bkg, backgroundPosition: 'top left'}
];

export const ARTICLES_BACKGROUND = [m1, m2, m3];
