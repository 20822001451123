import { memo, useEffect, useState, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { isNil, size } from 'lodash';
// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import {
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// Local
import { ChatMessage } from '../../graphql/types';
import { MessageRefs } from '../types';
import { useApi } from '../../context/ApiProvider';
import { MIN_DRAWER, MEDIUM_SIZE } from '../../config/params';
import ListSources from '../ListSources';
import ListChats from '../ListChats';
import ListOldQuestions from '../ListOldQuestions';
import MenuSideBarHeader from './MenuSideBarHeader';

type MenuSideBarProps = {
  chatId?: string;
  drawerOn?: boolean;
  history?: ChatMessage[];
  itemRefs?: MessageRefs;
  window?: () => Window;
};

const MenuSideBarPropTypes = {
  chatId: PropTypes.string,
  drawerOn: PropTypes.bool,
  history: PropTypes.array as Validator<ChatMessage[]>,
  itemRefs: PropTypes.object as Validator<MessageRefs>,
};

const MenuSideBar: FunctionComponent<MenuSideBarProps> = ({
  chatId,
  history,
  itemRefs,
  window,
}) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(MEDIUM_SIZE));

  const [loading, setLoading] = useState(true);

  const {
    getChats: { getChats, chats },
    getDrawer: { drawerOn, handleSetDrawer, handleToggleDrawer },
  } = useApi();

  useEffect(() => {
    if (isNil(chats)) {
      setLoading(true);
      getChats();
    }
  }, [chats, getChats]);

  useEffect(() => {
    if (!isNil(chats)) {
      setLoading(false);
    }
  }, [chats]);

  useEffect(() => {
    if (!isNil(chats) && !size(chats)) {
      handleSetDrawer(false);
    }
  }, [chats, handleSetDrawer]);

  const menuContent = () => {
    return (
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        overflow="hidden"
        my={0}
        px={0}
        sx={{
          minWidth: `${MIN_DRAWER}rem`,
          maxWidth: `${MIN_DRAWER}rem`,
          backgroundColor: '#fafafa',
        }}
      >
        {loading ? (
          <CircularProgress thickness={6} sx={{ margin: '0 auto' }} />
        ) : (
          <List sx={{ overflow: 'auto', p: 0 }}>
            <ListSources />
            <ListChats chatId={chatId} />
            <ListOldQuestions history={history} itemRefs={itemRefs} />
          </List>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {isDesktop ? (
        <Slide
          direction="right"
          in={Boolean(drawerOn)}
          mountOnEnter
          unmountOnExit
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            sx={{
              minWidth: `${drawerOn ? MIN_DRAWER : 0}rem`,
              backgroundColor: `${drawerOn ? '#fafafa' : 'inherent'}`,
              px: 1,
              py: 2,
              pt: '12px',
            }}
          >
            <MenuSideBarHeader />
            {menuContent()}
          </Box>
        </Slide>
      ) : (
        <Drawer
          onClick={handleToggleDrawer}
          container={container}
          variant="temporary"
          open={drawerOn}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            minWidth: `${MIN_DRAWER}rem`,
            maxWidth: `${MIN_DRAWER}rem`,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
            },
            pt: '12px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              pt: '0.5rem',
              pl: '1.0rem',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{ mr: 2 }}
            >
              <MenuOpenIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Biblum
            </Typography>
          </Box>
          {menuContent()}
        </Drawer>
      )}
    </Box>
  );
};

MenuSideBar.propTypes = MenuSideBarPropTypes;

export default memo(MenuSideBar);
