import {
  memo,
  useState,
  useEffect,
  useCallback,
  useRef,
  type ReactNode,
  type FunctionComponent,
} from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isUndefined from 'lodash/isUndefined';
// MUI
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ArrowDownward } from '@mui/icons-material';
// Local
/* import { MIN_HISTORY } from '../config/params'; */
import { type LIRef, type MessageRefs } from '../components/types';
import { useApi } from '../context/ApiProvider';
import { setSPos, getSPos } from '../helpers/ScrollPosition';
import FetchFailedAlert from '../components/FetchFailedAlert';
import { ChatMessage } from '../graphql/types';
import NoHistoryBanner from './NoHistoryBanner';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';

type ChatHistoryProps = {
  chat?: string;
  history?: ChatMessage[];
  itemRefs?: MessageRefs;
  pending?: boolean;
  failed?: boolean;
  children?: ReactNode | ReactNode[];
};

const ChatHistoryPropTypes = {
  chat: PropTypes.string,
  history: PropTypes.array as Validator<ChatMessage[]>,
  itemRefs: PropTypes.object as Validator<MessageRefs>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const ChatHistory: FunctionComponent<ChatHistoryProps> = ({
  chat,
  history,
  itemRefs,
  pending,
  failed,
  children,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [showFab, setShowFab] = useState(false);
  const {
    getPCH: { setPHistory, prevHistory },
  } = useApi();

  const scrollToEnd = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
      setShowFab(false);
    }
  }, [listRef]);

  const handleShowFab = useCallback(() => {
    if (listRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = listRef.current;
      setShowFab(!(scrollHeight - scrollTop - clientHeight <= 1));
      setSPos('chat-history', scrollTop);
    } else {
      setShowFab(false);
    }
  }, []);

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', handleShowFab);
    }
    // Cleanup event listener on component unmount
    return () => {
      if (list) {
        list.removeEventListener('scroll', handleShowFab);
      }
    };
  }, [history, handleShowFab]);

  useEffect(() => {
    if (!pending && !failed && history && itemRefs?.current) {
      const restoring = !isUndefined(prevHistory) && history === prevHistory;
      if (restoring) {
        const scroll = getSPos('chat-history');
        if (scroll && listRef.current) {
          listRef.current.scrollTo(0, scroll);
        }
      } else {
        const hs = size(history);
        if (hs >= 2) {
          const item = itemRefs.current[hs - 2]?.current as HTMLLIElement;
          item?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
    return () => {
      setPHistory(history);
    };
  }, [pending, failed, history, itemRefs, setPHistory, prevHistory]);

  useEffect(() => handleShowFab());

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="flex-end"
      overflow="hidden"
      my={0}
      px={1}
      style={{ width: '100%' }}
    >
      {((size(history) || pending || failed) && (
        <>
          <List ref={listRef} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {map(history, (item, index) => (
              <ListItem
                key={index}
                ref={itemRefs?.current?.[index] as LIRef}
                sx={{ display: 'flex', flexWrap: 'wrap' }}
              >
                {(item.sender === 'Bot' && (
                  <BotMessage
                    id={item.id}
                    chat={chat}
                    text={item.text}
                    status={item.status}
                    verses={item.verses}
                  />
                )) || (
                  <UserMessage
                    text={item.text}
                    index={index}
                    messageRefs={itemRefs}
                  />
                )}
              </ListItem>
            ))}
            {failed && (
              <ListItem>
                <FetchFailedAlert />
              </ListItem>
            )}
          </List>
        </>
      )) || <NoHistoryBanner />}
      <Box sx={{ position: 'relative', display: 'flex', flexShrink: 0 }}>
        {showFab && (
          <Fab
            size="small"
            color="primary"
            onClick={scrollToEnd}
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%)',
              top: '-40px',
            }}
          >
            <ArrowDownward />
          </Fab>
        )}
        {children}
      </Box>
    </Box>
  );
};

ChatHistory.propTypes = ChatHistoryPropTypes;

export default memo(ChatHistory);
