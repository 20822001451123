import { memo, useMemo, useEffect, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { useMediaQuery, useTheme } from '@mui/material';
// Local
import { useApi } from '../context/ApiProvider';
import FetchFailedAlert from './FetchFailedAlert';
import Markdown from './Markdown';
import VerseCard from './VerseCard';
import VerseItem from './VerseItem';
import TabCommentaries from './TabCommentaries';
import style from './RelatedVerses.module.scss';
import VerseExplorerTitle from './VerseExplorerTitle';
import { MEDIUM_SIZE, PAGE_SIZE_LARGE } from '../config/params';


const RelatedVerses: FunctionComponent = () => {
  const { t } = useTranslation();
  const { chatId, verseId } = useParams();
  const {
    getVerses: { getVerses, data, failed, pending },
    getExcerpt: {
      getExcerpt,
      excerptVerses,
      failed: failedE,
      pending: pendingE,
    },
    getCommentaries: {
      getCommentaries,
      commentaries,
      failed: failedC,
      pending: pendingC,
    },
    getHBackground: {
      getHBackground,
      background,
      failed: failedB,
      pending: pendingB,
    },
  } = useApi();
  const { related } = data || {};

  useEffect(() => {
    if (!isNil(verseId)) {
      getVerses?.(verseId);
      getCommentaries?.(verseId);
      getHBackground?.(verseId);
      getExcerpt?.(verseId, PAGE_SIZE_LARGE);
    }
  }, [verseId, getVerses, getCommentaries, getHBackground, getExcerpt]);

  const commentariesList = useMemo(() => {
    if (failedC) {
      return [];
    }
    return commentaries || [];
  }, [commentaries, failedC]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(MEDIUM_SIZE));

  return (
    <Box
      sx={{
        width: '100%',
        px: '1rem',
        pb: '1rem',
        pt: '1rem',
      }}
    >
      {verseId && (
        <>
          {((failed || failedE) && <FetchFailedAlert />) ||
            ((pending || pendingC || pendingE || pendingB) && (
              <Stack sx={{ width: '100%' }} spacing={1}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            )) || (
              <Grid container direction="column">
                <Grid item xs={12}>
                  {isSmallScreen && <VerseExplorerTitle />}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    component="div"
                    pb={'1rem'}
                    color="rgba(0 0 0 / 70%)"
                  >
                    {t('related.verses.title')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box className={style['excerpts']}>
                    <Card className={style['excerpts-card']}>
                      {map(excerptVerses, (excerptVerse, index) => (
                        <VerseItem verse={excerptVerse} unclickable key={index}/>
                      ))}
                    </Card>
                  </Box>
                </Grid>
                {size(commentariesList) > 0 && (
                  <>
                    <Grid item xs={12} sx={{ maxHeight: '4rem' }}>
                      <Typography
                        variant="h6"
                        component="div"
                        pb={'1rem'}
                        color="rgba(0 0 0 / 70%)"
                      >
                        {t('related.verses.commentaries')}
                      </Typography>
                    </Grid>

                    <TabCommentaries commentaries={commentariesList} />
                  </>
                )}
                {size(background) > 0 && !failedB && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="div"
                        pb={'1rem'}
                        color="rgba(0 0 0 / 70%)"
                      >
                        {t('related.verses.background')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ pb: '1rem', width: '100%', maxWidth: '100%' }}
                    >
                      <Card sx={{ p: '1rem', height: '100%' }}>
                        <Markdown text={background?.text || ''} />
                      </Card>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" pb={'0.7rem'}>
                    <Typography
                      variant="h6"
                      component="div"
                      pb={'0.3rem'}
                      color="rgba(0 0 0 / 70%)"
                    >
                      {t('related.verses.label')}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      px={'0.5rem'}
                      color="rgba(0 0 0 / 50%)"
                    >
                      {t('bot.verses.label.click')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  container
                  rowSpacing={1}
                  columnSpacing={1}
                  direction="row"
                >
                  {map(related, (vrse, index) => (
                    <Grid item key={index} xs={6} sm={4} md={4}>
                      <VerseCard verse={vrse} chat={chatId} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
        </>
      )}
    </Box>
  );
};

export default memo(RelatedVerses);
