import {
  memo,
  useState,
  useEffect,
  useRef,
  type FunctionComponent,
} from 'react';
import PropTypes, { Validator } from 'prop-types';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import isUndefined from 'lodash/isUndefined';
import size from 'lodash/size';
// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useMediaQuery, useTheme } from '@mui/material';
// local
import {
  PENDING_STATUS,
  FAILED_STATUS,
  POLLING_INTERVAL,
  MAX_POLLING_ITERATIONS, MEDIUM_SIZE,
} from '../config/params';
import { useApi } from '../context/ApiProvider';
import { ChatMessagePayload, Verse } from '../graphql/types';
import LoadingMsgPlaceholder from './LoadingMsgPlaceholder';
import ListVerses from './ListVerses';
import Markdown from './Markdown';

type BotMessageProps = {
  id?: string;
  chat?: string;
  text?: string;
  status?: string | null;
  verses?: Verse[] | null;
  hideVerses?: boolean;
  testPending?: boolean;
  testTooLong?: boolean;
};

const BotMessagePropTypes = {
  id: PropTypes.string,
  chat: PropTypes.string,
  text: PropTypes.string,
  status: PropTypes.string,
  verses: PropTypes.array as Validator<Verse[] | null>,
  hideVerses: PropTypes.bool,
  testPending: PropTypes.bool,
  testTooLong: PropTypes.bool,
};

const BotMessage: FunctionComponent<BotMessageProps> = ({
  id: message_id,
  chat: chatId,
  text: message_text,
  status,
  verses: message_verses,
  hideVerses,
  testPending,
  testTooLong,
}) => {
  const { t } = useTranslation();
  const {
    getMessage: { getMessage, failed, setPolling },
  } = useApi();
  const ref = useRef<HTMLDivElement>();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(MEDIUM_SIZE));

  const [id, setId] = useState(message_id);
  const [chat, setChat] = useState(chatId);
  const [text, setText] = useState(message_text);
  const [verses, setVerses] = useState(message_verses);
  const [pending, setPending] = useState(toString(status) === PENDING_STATUS);
  const [statusFailed, setStatusFailed] = useState(
    toString(status) === FAILED_STATUS
  );
  const [iteration, setIteration] = useState(
    testTooLong ? MAX_POLLING_ITERATIONS : 0
  );

  useEffect(() => {
    setChat(chatId);
    setId(message_id);
    setText(message_text);
    setVerses(message_verses);
    setPending(toString(status) === PENDING_STATUS);
    setStatusFailed(toString(status) === FAILED_STATUS);
    setIteration(testTooLong ? MAX_POLLING_ITERATIONS : 0);
  }, [status, message_text, message_verses, message_id, chatId, testTooLong]);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    if (
      pending &&
      !statusFailed &&
      !failed &&
      !isUndefined(id) &&
      !isUndefined(chat) &&
      isUndefined(testPending)
    ) {
      if (iteration < MAX_POLLING_ITERATIONS) {
        setPolling?.(id, true);
        interval.current = setInterval(() => {
          getMessage?.({
            chat,
            id,
            onCompleted: (data?: ChatMessagePayload | null) => {
              const isPending = toString(data?.data?.status) === PENDING_STATUS;
              setPolling?.(id, isPending);
              setPending(isPending);
              setStatusFailed(toString(data?.data?.status) === FAILED_STATUS);
              if (isPending) {
                setIteration(iteration + 1);
              } else {
                setText(toString(data?.data?.text));
              }
            },
          });
        }, POLLING_INTERVAL);
        return () => {
          if (interval.current) {
            clearInterval(interval.current);
            interval.current = null;
          }
        };
      }
    }
    return undefined;
  }, [
    pending,
    id,
    chat,
    getMessage,
    iteration,
    statusFailed,
    failed,
    testPending,
    setPolling,
  ]);

  useEffect(() => {
    if (ref.current && pending) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [pending]);

  return (
    <Grid container sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {((failed || statusFailed) && (
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Alert severity="error">{t('error.fetch_failed')}</Alert>
          </Box>
        </Grid>
      )) ||
        (pending && (
          <Grid item xs={12}>
            {(iteration < MAX_POLLING_ITERATIONS && (
              <LoadingMsgPlaceholder />
            )) || (
              <Box sx={{ width: '100%' }}>
                <Alert severity="error">{t('bot.message.to.long')}</Alert>
              </Box>
            )}
          </Grid>
        )) || (
          <>
            <Grid item xs={hideVerses || !isDesktop ? 12 : 8}>
              <Box ref={ref}>
                {size(verses) > 0 && (
                  <Typography
                    variant="h6"
                    component="div"
                    pb={'0.5rem'}
                    color={'rgba(0 0 0 / 60%)'}
                  >
                    {t('bot.answer.label')}
                  </Typography>
                )}
                <Markdown text={text} />
              </Box>
            </Grid>
            {!hideVerses && !pending && !statusFailed && (
              <Grid item container sx={{ flex: '1', pt: '1rem' }}>
                {size(verses) > 0 && (
                  <Box pl={'1rem'} pb={'1rem'} sx={{ width: '100%' }}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="h6"
                        component="div"
                        pb={'0.5rem'}
                        color={'rgba(0 0 0 / 60%)'}
                      >
                        {t('bot.verses.label')}
                      </Typography>
                      <Box flexGrow={1} />
                      <Typography
                        variant="subtitle2"
                        component="div"
                        px={'0.1rem'}
                        color="rgba(0 0 0 / 50%)"
                      >
                        {t('bot.verses.label.click')}
                      </Typography>
                    </Box>
                    <ListVerses
                      verses={verses}
                      direction={isDesktop ? 'column' : 'row'}
                      truncated
                      chat={chat}
                    />
                  </Box>
                )}
              </Grid>
            )}
          </>
        )}
    </Grid>
  );
};

BotMessage.propTypes = BotMessagePropTypes;

export default memo(BotMessage);
