import { memo, type FunctionComponent } from 'react';
// MUI
import Box from '@mui/material/Box';
// Local imports
import RelatedVerses from '../components/RelatedVerses';
import DefaultHeader from '../components/common/DefaultHeader';

const VersesPanel: FunctionComponent = () => {
  return (
    <Box className="page-wrapper">
      {/* Header */}
      <DefaultHeader showVersesExplorer showShareVerse />
      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <RelatedVerses />
      </Box>
    </Box>
  );
};

// Default export
export default memo(VersesPanel);
