import {
  memo, useState, useEffect, useCallback, useRef,
  type MouseEvent, type ChangeEvent, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@apollo/client';
import size from 'lodash/size';
import toString from 'lodash/toString';
// MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
// Local
import { cache as appCache } from '../../graphql/cache';
import { PATH_HOME, PATH_LOGIN, PATH_SIGNUP_CONFIRMED, injectParams } from '../../config/paths';
import { MIN_PASSWORD_LENGTH } from '../../config/params';
import ActionFailedAlert from '../../components/ActionFailedAlert';
import useMutationMethod from '../../hooks/useMutationMethod';
import { SIGNUP_MUTATION } from '../../graphql/Signup';
import { SignupMutation, SignupDocument } from '../../graphql/types';
import logoImage from '../../images/biblum-logo-transparent-1.png';

type SignUpProps = {
  testSignup?: boolean;
}

const SignUpPropTypes = {
  testSignup: PropTypes.bool
};

const SignUp: FunctionComponent<SignUpProps> = ({
  testSignup
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(true);
  const [confirmed, setConfirmed] = useState('');
  const [validConfirmed, setValidConfirmed] = useState(true);
  const [disabledSignup, setDisabledSignup] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { mutate: signup, loading, failed } = useMutationMethod({
    mutation: useMutation(SIGNUP_MUTATION as typeof SignupDocument)
  });

  const handleEmailChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setEmail(toString(event.target.value));
    // don't remember from where i copied this code, but this works.
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(re.test(toString(event.target.value)));
  }, []);

  const handlePasswordChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setPassword(toString(event.target.value));
    setValidPassword(Boolean(size(toString(event.target.value)) >= MIN_PASSWORD_LENGTH))
  }, []);

  const handleConfirmedChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setConfirmed(toString(event.target.value));
    setValidConfirmed(Boolean(size(toString(event.target.value)) >= MIN_PASSWORD_LENGTH) &&
      (password === event.target.value));
  }, [password]);

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);

  const handleMouseDownPassword = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  useEffect(() => (
    setDisabledSignup(
      !(validEmail &&
        size(password)>= MIN_PASSWORD_LENGTH &&
        size(confirmed)>= MIN_PASSWORD_LENGTH &&
        (password === confirmed))
    )
  ), [validEmail, password, confirmed]);

  const handleSubmit = useCallback(() => {
    if(size(email) && (size(password) >= MIN_PASSWORD_LENGTH)) signup?.({
      variables: { input: { username: email, password } },
      onCompleted: (data: SignupMutation) => {
        const message = state?.message;
        const token = data.signup.data.tokens.access_token
        if (token) {
          localStorage.setItem('token', token);
          localStorage.setItem('role', 'user');
          const path = injectParams(PATH_SIGNUP_CONFIRMED,
            { username: encodeURIComponent(data.signup.data.user.username || 'user') });
          appCache.evict({ id: 'ROOT_QUERY', fieldName: 'chats' });
          appCache.evict({ id: 'ROOT_QUERY', fieldName: 'settings' });
          navigate(path, { state: { signup: true, ...(message ? { message } : {}) } });
        } else {
          navigate(PATH_LOGIN, { state: { signup: true, ...(message ? { message } : {}) } });
        }
      }
    });
  }, [email, password, signup, state, navigate]);

  const toLogin = useCallback(() => {
    navigate(PATH_LOGIN, { state: state });
  }, [state, navigate]);

  const toHome = useCallback(() => {
    navigate(PATH_HOME, { state: state });
  }, [state, navigate]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const currentTarget = event.currentTarget as HTMLElement;
      const inputElement = currentTarget.querySelector('input');
      if (inputElement === emailRef.current) {
        passwordRef.current?.focus();
      } else if (inputElement === passwordRef.current) {
        confirmPasswordRef.current?.focus();
      } else if (inputElement === confirmPasswordRef.current) {
        submitButtonRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    if(testSignup) signup?.({ variables: { input: { username: email, password } } });
  }, [testSignup, email, password, signup]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{width: '100%', mx: 'auto', overflowY: 'auto'}}
    >
      <Box display='flex' justifyContent='center' py={'4rem'}>
        <Link
          onClick={toHome}
          rel="noopener noreferrer"
          underline='none'
          sx={{cursor: 'pointer'}}
        >
          <img src={logoImage} width='40rem'/>
        </Link>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        pb={'1rem'}
        sx={{textAlign: 'center'}}
      >
        <Typography variant='h5' component='div' color={'rgba(0 0 0 / 70%)'} pb={'0.5rem'}>
          {t('singup.title')}
        </Typography>
        <Typography variant='body1' component='div' color={'rgba(0 0 0 / 70%)'}>
          <Trans i18nKey="signup.subtitle" />
        </Typography>
      </Box>
      <FormControl component='form'>
        <Box pb={'1rem'}>
          <TextField
            fullWidth
            size='small'
            type='email'
            label={t('email')}
            onChange={handleEmailChange}
            error={!validEmail}
            disabled={loading}
            autoComplete='off'
            inputRef={emailRef}
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box pb={'1rem'}>
          <TextField
            fullWidth
            size='small'
            label={t('password')}
            type={showPassword ? 'text' : 'password'}
            onChange={handlePasswordChange}
            error={!validPassword}
            disabled={loading}
            autoComplete='off'
            inputRef={passwordRef}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box pb={'1rem'}>
          <TextField
            fullWidth
            size='small'
            label={t('password.confirm')}
            type={showPassword ? 'text' : 'password'}
            onChange={handleConfirmedChange}
            error={!validConfirmed}
            disabled={loading}
            autoComplete='off'
            inputRef={confirmPasswordRef}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Button
          variant='contained'
          onClick={handleSubmit}
          endIcon={loading ? <CircularProgress color='inherit' size='1.2rem'/> : undefined}
          disabled={loading || disabledSignup}
          sx={{textTransform: 'none', fontSize: '1rem'}}
          ref={submitButtonRef}
        >
          {t('signup')}
        </Button>
        <Box
          display='flex'
          justifyContent='center'
          py={'2rem'}
        >
          <Box display='flex' alignItems='center'>
            {t('signup.to.login')}
            <Link
              onClick={toLogin}
              rel='noopener noreferrer'
              underline='none'
              pl='0.5rem'
              sx={{cursor: 'pointer'}}
            >
              {t('login')}
            </Link>
          </Box>
        </Box>
        <ActionFailedAlert
          open={failed}
          message='signup.error'
        />
      </FormControl>
    </Box>
  );
};

SignUp.propTypes = SignUpPropTypes;

export default memo(SignUp);
